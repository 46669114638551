.container-body {
    background: url('https://www.practicalbusinessskills.com/assets/js/questionnaire/js/quiz_v2/res/quiz_bg.png') no-repeat center;
}
.container {
  margin: 0 auto;
  max-width: 1170px;
  padding: 80px 50px;
}
.right-padding-100 {
  padding-right: 100px;
}

.quiz2-header-title {
  font-size: 16px;
  line-height: 30px;
  font-weight: lighter;
  margin: 0;
  padding: 0;
}
.quiz2-header-number {
  text-align: right;
  font-size: 16px;
  line-height: 26px;
  font-weight: lighter;
  margin: 0;
  padding: 0;
}
.quiz2-circle {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 1px solid #fff;
  border-radius: 15px;
  text-align: center;
  padding: 0;
  margin-right: 12px;
}
.quiz2-intro-title {
  font-size: 32px;
  line-height: 40px;
  font-weight: lighter;
  margin-top: 40px;
}
.quiz2-intro-text {
  font-size: 16px;
  line-height: 24px;
  font-weight: lighter;
  margin-top: 30px;
  margin-bottom: 70px;
}
.quiz2-intro-buttons {
  text-align: center;
}
.quiz2-button {
  display: inline-block;
  width: 100%;
  height: 42px;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  text-transform: uppercase;
  background-color: transparent;
  letter-spacing: 2px;
  border: 1px solid #ffffff;
  margin-bottom: 10px;
}
.quiz2-button.selected:hover {
  color: #ffffff;
  background-color: rgba(255, 255, 255, 0.18);
}
.quiz2-button.selected {
  color: #3453ac;
  background-color: #ffffff;
}
.quiz2-main-title {
  font-size: 24px;
  line-height: 30px;
  font-weight: lighter;
  margin-top: 25px;
}
.quiz2-answers-group {
  text-align: center;
}
.quiz2-answers {
  text-align: left;
  margin: 25px 0 16px 0px;
}
.quiz2-answer {
  width: 100%;
  display: table;
  table-layout: fixed;
  font-size: 15px;
  line-height: 22px;
  font-weight: normal;
  color: #ffffff;
  border: 1px solid #ffffff;
  background-color: transparent;
  margin: 10px 0;
  padding: 6px 0px;
}
.quiz2-answer:hover {
  background-color: rgba(255, 255, 255, 0.18);
}
.quiz2-answer-letter {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  width: 36px;
  border-right: 1px solid #afbad9;
}
.quiz2-answer-text {
  display: table-cell;
  padding: 7px 16px;
}
.quiz2-answer.selected {
  background-color: #ffffff;
  color: #3453ac;
  border: 1px solid #3453ac !important;
}
.quiz2-main-buttons {
  text-align: center;
}
.quiz2-button {
  display: inline-block;
  width: 100%;
  height: 42px;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  text-transform: uppercase;
  background-color: transparent;
  letter-spacing: 2px;
  border: 1px solid #ffffff;
  margin-bottom: 10px;
}
.quiz2-xs-hide {
  display: none;
}
.quiz2-gauge {
}
.quiz2-gauge-solid {
  display: inline-block;
  height: 17px;
  border-bottom: 2px solid #fff;
}
.quiz2-gauge-dotted {
  display: inline-block;
  height: 17px;
  border-bottom: 1px dotted #fff;
}
.quiz2-result-title {
  font-size: 20px;
  line-height: 20px;
  font-weight: normal;
  margin-top: 25px;
}
.quiz2-result-text {
  font-size: 16px;
  line-height: 24px;
  font-weight: lighter;
  margin-top: 16px;
}
.quiz2-resources {
  margin-top: 22px;
}
.quiz2-resource-line {
  border: 0;
  height: 1px;
  background: #ffffff;
  margin-top: 20px;
  margin-bottom: 20px;
}
a.quiz2-resource {
  display: block;
  height: 21px;
  background-image: url('https://www.practicalbusinessskills.com/assets/js/questionnaire/js/quiz_v2/res/resource_arrow.png');
  background-repeat: no-repeat;
  background-position: right;
  font-size: 16px;
  font-weight: lighter;
  line-height: 16px;
  color: #ffffff;
  text-decoration: none;
}

a.quiz2-resource:hover {
  background-image: url('https://www.practicalbusinessskills.com/assets/js/questionnaire/js/quiz_v2/res/resource_arrow_hover.png');
  text-decoration: none;
}
.quiz2-resource-line {
  border: 0;
  height: 1px;
  background: #ffffff;
  margin-top: 20px;
  margin-bottom: 20px;
}
.quiz2-result-buttons {
  text-align: left;
  margin-top: 20px;
}
.quiz2-result-social {
  float: right;
  margin-top: 8px;
}
.quiz2-result-social a {
  margin-left: 15px;
}
@media (min-width: 768px) {
  .quiz2-result-social {
    float: right;
    margin-top: 18px;
  }
  .quiz2-resource-line {
    border: 0;
    height: 1px;
    background: #ffffff;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .quiz2-result-text {
    font-size: 24px;
    line-height: 30px;
    font-weight: lighter;
    margin-top: 18px;
  }
  .quiz2-result-title {
    font-size: 31px;
    line-height: 31px;
    font-weight: normal;
    margin-top: 85px;
  }
  .quiz2-xs-hide {
    display: block;
  }
  .quiz2-button {
    display: inline-block;
    width: auto;
    height: 50px;
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
    text-transform: uppercase;
    background-color: transparent;
    letter-spacing: 2px;
    border: 1px solid #ffffff;
    padding: 0 30px;
  }
  .quiz2-main-buttons {
    text-align: right;
  }
  .quiz2-answer-text {
    display: table-cell;
    padding: 9px 22px;
  }
  .quiz2-answer-letter {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    width: 48px;
    border-right: 1px solid #afbad9;
  }
  .quiz2-answer {
    width: 100%;
    display: table;
    table-layout: fixed;
    font-size: 20px;
    line-height: 30px;
    font-weight: normal;
    color: #ffffff;
    border: 1px solid #afbad9;
    background-color: transparent;
    margin: 13px 0;
    padding: 8px 0;
  }
  .quiz2-answers {
    text-align: left;
    margin: 50px 0 30px 0px;
  }
  .quiz2-header-title {
    font-size: 30px;
    line-height: 40px;
    font-weight: lighter;
    margin: 0;
    padding: 0;
  }
  .quiz2-header-number {
    text-align: right;
    font-size: 24px;
    line-height: 36px;
    font-weight: lighter;
    margin: 0;
    padding: 0;
  }
  .quiz2-circle {
    display: inline-block;
    width: 40px;
    height: 40px;
    border: 2px solid #fff;
    border-radius: 20px;
    text-align: center;
    padding: 0;
    margin-right: 15px;
  }
  .quiz2-intro-title {
    font-size: 52px;
    line-height: 74px;
    font-weight: lighter;
    margin-top: 50px;
  }
  .quiz2-intro-text {
    font-size: 18px;
    line-height: 27px;
    font-weight: lighter;
    margin-top: 45px;
    margin-bottom: 60px;
  }
  .quiz2-intro-buttons {
    text-align: left;
  }
  .quiz2-button {
    display: inline-block;
    width: auto;
    height: 50px;
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
    text-transform: uppercase;
    background-color: transparent;
    letter-spacing: 2px;
    border: 1px solid #ffffff;
    padding: 0 30px;
  }
  .quiz2-main-title {
    font-size: 50px;
    line-height: 62px;
    font-weight: lighter;
    margin-top: 50px;
  }
  .quiz2-answers-group {
    text-align: center;
  }
  .quiz2-resources {
    margin-top: 85px;
  }
  a.quiz2-resource {
    display: block;
    height: 21px;
    background-image: url('https://www.practicalbusinessskills.com/assets/js/questionnaire/js/quiz_v2/res/resource_arrow.png');
    background-repeat: no-repeat;
    background-position: right;
    font-size: 18px;
    font-weight: lighter;
    line-height: 18px;
    color: #ffffff;
    text-decoration: none;
  }
  .quiz2-resource-line {
    border: 0;
    height: 1px;
    background: #ffffff;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .quiz2-result-buttons {
    text-align: left;
    margin-top: 100px;
  }
  .quiz2-result-social a {
    margin-left: 15px;
  }
}

@media (min-width: 1200px) {
  .quiz2-result-social {
    float: right;
    margin-top: 18px;
  }
  .quiz2-resource-line {
    border: 0;
    height: 1px;
    background: #ffffff;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .quiz2-result-title {
    font-size: 52px;
    line-height: 52px;
    font-weight: normal;
    margin-top: 75px;
  }
  .quiz2-xs-hide {
    display: block;
  }
  .quiz2-main-buttons {
    text-align: right;
  }
  .quiz2-answer-letter {
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    width: 44px;
    border-right: 1px solid #afbad9;
  }
  .quiz2-answer {
    width: 100%;
    display: table;
    table-layout: fixed;
    font-size: 18px;
    line-height: 27px;
    font-weight: normal;
    color: #ffffff;
    border: 1px solid #ffffff;
    background-color: transparent;
    margin: 13px 0;
    padding: 8px 0;
  }
  .quiz2-header-title {
    font-size: 30px;
    line-height: 40px;
    font-weight: lighter;
    margin: 0;
    padding: 0;
  }
  .quiz2-header-number {
    text-align: right;
    font-size: 24px;
    line-height: 36px;
    font-weight: lighter;
    margin: 0;
    padding: 0;
  }
  .quiz2-circle {
    display: inline-block;
    width: 40px;
    height: 40px;
    border: 2px solid #fff;
    border-radius: 20px;
    text-align: center;
    padding: 0;
    margin-right: 15px;
  }
  .quiz2-intro-title {
    font-size: 52px;
    line-height: 74px;
    font-weight: lighter;
    margin-top: 50px;
  }
  .quiz2-intro-text {
    font-size: 18px;
    line-height: 27px;
    font-weight: lighter;
    margin-top: 50px;
    margin-bottom: 40px;
  }
  .quiz2-intro-buttons {
    text-align: left;
  }
  .quiz2-button {
    display: inline-block;
    width: auto;
    height: 50px;
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
    text-transform: uppercase;
    background-color: transparent;
    letter-spacing: 2px;
    border: 1px solid #ffffff;
    padding: 0 30px;
  }
  .quiz2-main-title {
    font-size: 40px;
    line-height: 64px;
    font-weight: lighter;
    margin-top: 50px;
  }
  .quiz2-answers-group {
    text-align: right;
  }
  .quiz2-answers {
    text-align: left;
    margin: 50px 0 30px 0px;
  }
  .quiz2-answer-text {
    display: table-cell;
    padding: 9px 20px;
  }
  .quiz2-button {
    display: inline-block;
    width: auto;
    height: 50px;
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
    text-transform: uppercase;
    background-color: transparent;
    letter-spacing: 2px;
    border: 1px solid #ffffff;
    padding: 0 30px;
  }
  .quiz2-result-text {
    font-size: 27px;
    line-height: 32px;
    font-weight: lighter;
    margin-top: 26px;
  }
  .quiz2-resources {
    margin-top: 75px;
  }
  a.quiz2-resource {
    display: block;
    height: 21px;
    background-image: url('https://www.practicalbusinessskills.com/assets/js/questionnaire/js/quiz_v2/res/resource_arrow.png');
    background-repeat: no-repeat;
    background-position: right;
    font-size: 18px;
    font-weight: lighter;
    line-height: 18px;
    color: #ffffff;
    text-decoration: none;
  }
  .quiz2-resource-line {
    border: 0;
    height: 1px;
    background: #ffffff;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .quiz2-result-buttons {
    text-align: left;
    margin-top: 58px;
  }
  .quiz2-result-social a {
    margin-left: 15px;
  }
}
